<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-db-firma-info"
      title="Veri Tabanı Ekle"
      ok-title="Kaydet"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <b-form
        ref="form"
        @submit.stop.prevent="saveDbFirmaInfo">
        <b-row>
          <b-col cols="12" md="12" lg="12">
             <b-form-group label="Sunucu Adı" label-for="sDBServerName">
              <b-form-input id="sDBServerName" type="text" v-model="dbInfoData.sDBServerName" />
            </b-form-group>
            <b-form-group label="Veri Tabanı Adı" label-for="sDatabaseName">
              <b-form-input id="sDatabaseName" type="text" v-model="dbInfoData.sDatabaseName" />
            </b-form-group>
            <b-form-group label="Kullanıcı Adı" label-for="sDBUserName">
              <b-form-input id="sDBUserName" type="text" v-model="dbInfoData.sDBUserName" />
            </b-form-group>
             <b-form-group label="Parola" label-for="sDBPassword">
              <b-form-input id="sDBPassword" type="text" v-model="dbInfoData.sDBPassword" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dbInfoData: {
        sDatabaseName: null,
        sDBServerName: null,
        sDBUserName: null,
        sDBPassword: null,
      },
    }
  },
  methods: {
    saveDbFirmaInfo() {
      this.$store.dispatch('dbInfoYonetim/saveDbFirmaInfos', this.dbInfoData)
        .then(response => {
          if (response.statusCode === 200) {
            this.modalObject.onSuccess(response);
            this.hiddenModal();

            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    initialModal() {
      this.$refs['modal-save-db-firma-info'].show();
      const dbInfoData = this.modalObject.item;
      if (dbInfoData) {
        this.dbInfoData = dbInfoData;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveDbFirmaInfo()
    },
    hiddenModal() {
      this.$refs['modal-save-db-firma-info'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<template>
<div>   
    <!-- Buttons -->
    <crud-button :onClickList="fetchDbFirmaInfos" :showAddNew="false" />
    <!-- Filters -->
    <!-- <dbFirmaInfoFilters />  -->
    
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>
        </div>
        <b-table
            ref="dbFirmaInfosTable"
            class="position-relative"
            :items="getdbFirmaInfos"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Hiçbir eşleşen kayıt bulunamadı"
            :sort-desc.sync="isSortDirDesc">

        <template #cell(sDatabaseName)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sDatabaseName }}</span>
          </div>
        </template>
        
        <template #cell(sDBUserName)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sDBUserName }}</span>
          </div>
        </template>

        <template #cell(sDBPassword)="data">
          <b-input-group style="width:150px" class="input-group-merge">
            <b-form-input
                  v-model="data.item.sDBPassword"
                  :type="data.item.passwordHidden ? 'password' : 'text'"
                  :disabled="true"
                  size="sm"
                  class="form-control-merge"
                  placeholder="Parola"
                />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="data.item.passwordHidden ? 'EyeIcon' : 'EyeOffIcon'"
                  @click="data.item.passwordHidden = !data.item.passwordHidden"
                />
              </b-input-group-append>
          </b-input-group>
        </template>

        <template #cell(isActive)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="data.item.isActive"
              class="custom-control-success"
              name="check-button"
              @change="deactivate(data.item)"
              >
              {{ $t('Aktif/Pasif') }}
            </b-form-checkbox>
            
            <b-form-checkbox
              :checked="data.item.isActiveIrsaliyeService"
              class="custom-control-success"
              name="check-button"
              @change="deactivateIrsaliyeService(data.item)"
              >
              {{ $t('Irsaliye Servis') }}
            </b-form-checkbox>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Update -->
          <feather-icon
            :id="`user-row-${data.item.id}-edit-icon`"
            v-b-modal.modal-save-db-firma-info
            @click="update(data.item)"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="toplamdbFirmaInfo"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <SaveDbFirmaInfo v-if="modalObject.showModal" :modalObject="modalObject" />
    </b-card>
</div>

</template>

<script>

// Alert
import AlertService from '@/common/alert.service'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import useDBFirmaInfos from './useDBFirmaInfos'
import SaveDbFirmaInfo from './SaveDbFirmaInfo.vue';

export default {
  components: {
    SaveDbFirmaInfo,
  },
  data() {
    return {
      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchDbFirmaInfos,
      },
    }
  },
  methods: {
    update(item) {
      Object.assign(this.modalObject.item, item);
      this.modalObject.showModal = true;
    },
    deactivateIrsaliyeService(item) {
      this.$store.dispatch('dbInfoYonetim/toggleIrsaliyeService', item.sDBGuid)
      .then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          });
        } else {
          AlertService.error(this, response.message);
        }
      })
    },
    deactivate(item) {
      this.$store.dispatch('dbInfoYonetim/toggleActivedbFirmaInfo', item.sDBGuid)
      .then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          });
        } else {
          AlertService.error(this, response.message);
        }
      })
    },
    fetchDbFirmaInfos() {
      this.fetchdbFirmaInfos();
    },
  },
  setup() {
    const {
        fetchdbFirmaInfos,
        getdbFirmaInfos,
        tableColumns,
        perPage,
        currentPage,
        toplamdbFirmaInfo,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        dbFirmaInfosTable,

        resolveDatabasename,
    } = useDBFirmaInfos()
  
    return {
        fetchdbFirmaInfos,
        getdbFirmaInfos,
        tableColumns,
        perPage,
        currentPage,
        toplamdbFirmaInfo,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        dbFirmaInfosTable,

        resolveDatabasename,
    }
  },
};
</script>

<style>
</style>

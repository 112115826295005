import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useDBFirmaInfos() {
  const dbFirmaInfosTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { label: 'Adı', key: 'sDatabaseName', sortable: true },
    { label: 'Sunucu Adı', key: 'sDBServerName', sortable: true },
    { label: 'Kullanıcı Adı', key: 'sDBUserName', sortable: true },
    { label: 'Parola', key: 'sDBPassword', sortable: true },
    { label: 'Aktif Durumu', key: 'isActive' },
    { label: 'Aksiyon', key: 'actions', class: 'text-right' },
  ]

  const perPage = ref(10)
  const toplamdbFirmaInfo = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['dbInfoYonetim/getdbFirmaInfoLocalFilters'].sortBy
    },
    set(value) {
      store.commit('dbInfoYonetim/setdbFirmaInfoFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['dbInfoYonetim/getdbFirmaInfoLocalFilters'].sortDesc
    },
    set(value) {
      store.commit('dbInfoYonetim/setdbFirmaInfoFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = dbFirmaInfosTable.value ? dbFirmaInfosTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamdbFirmaInfo.value,
    }
  })

  const refetchData = () => {
    dbFirmaInfosTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchdbFirmaInfos = () => {
    store.dispatch('dbInfoYonetim/fetchDbFirmaInfos')
  }

  const getdbFirmaInfos = computed(() => {
    const dbFirmaInfos = store.getters['dbInfoYonetim/getDbFirmaInfos']
    toplamdbFirmaInfo.value = dbFirmaInfos.length
    return dbFirmaInfos
  })

  onMounted(fetchdbFirmaInfos)

  return {
    fetchdbFirmaInfos,
    getdbFirmaInfos,
    tableColumns,
    perPage,
    currentPage,
    toplamdbFirmaInfo,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    dbFirmaInfosTable,
    }
}
